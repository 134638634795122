import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Swiper from "react-id-swiper"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"

const FW22 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "high-point-125-patent-pumps",
      "high-point-125-satin-pumps",
      "next-level-125-vitello-sandals",
      "zenith-125-vitello-boots",
      "zenith-125-patent-boots",
      "heights-125-capretto-boots",
      "heights-125-suede-boots",
      "gaze-65-nappa-sandals",
      "gaze-100-metallic-suede-sandals",
      "vivid-100-snake-print-pumps",
      "mackie-mesh-crystals-boots",
      "lynx-105-capretto-pumps",
      "trinity-knee-high-105-patent-boots",
      "trinity-knee-high-75-patent-boots",
      "trinity-mid-calf-75-patent-boots",
      "trinity-knee-high-75-nappa-boots",
      "trinity-knee-high-105-nappa-boots",
      "legacy-over-the-knee-75-calf-boots",
      "legacy-over-the-knee-100-calf-boots",
      "legacy-mid-calf-75-calf-boots",
      "legacy-mid-calf-100-calf-boots",
      "reflection-75-disco-ball-boots",
      "limelight-40-disco-ball-pumps",
      "limelight-90-disco-ball-pumps",
      "gloss-105-patent-pumps",
      "gloss-40-patent-pumps",
      "strapped-105-capretto-pumps",
      "strapped-75-capretto-pumps",
      "cantor-boots",
      "buckle-up-nappa-boots",
      "monday-morning-20-nappa-boots",
      "monday-morning-suede-boots",
      "weekend-20-suede-boots",
      // "halo-pvc-pumps",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  const swiperParams = {
    containerClass: `${styles.swiperContainer} swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: `.swiper-pagination`,
      clickable: true,
    },
    // allowTouchMove: false,
    on: {
      slideChangeTransitionEnd: function() {
        if (this) {
          const i = this.activeIndex
          const video = this.slides[i].querySelector("video")
          if (video)
            video.play()
        }
      },
    },
  }

  const [swiper1, getSwiper1] = useState(null)
  const swiperParams1 = {
    ...swiperParams,
    getSwiper: getSwiper1,
  }

  const [swiper2, getSwiper2] = useState(null)
  const swiperParams2 = {
    ...swiperParams,
    getSwiper: getSwiper2,
  }

  const [swiper3, getSwiper3] = useState(null)
  const swiperParams3 = {
    ...swiperParams,
    getSwiper: getSwiper3,
  }

  const [swiper4, getSwiper4] = useState(null)
  const swiperParams4 = {
    ...swiperParams,
    getSwiper: getSwiper4,
  }

  // const [swiper6, getSwiper6] = useState(null)
  const swiperParams6 = {
    ...swiperParams,
    // getSwiper: getSwiper6,
  }

  const [swiper7, getSwiper7] = useState(null)
  const swiperParams7 = {
    ...swiperParams,
    getSwiper: getSwiper7,
  }

  return (
    <div className={styles.ss22}>
      <SEO
        title="Fall Winter '22"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Block1_HighPoint_2000x.jpg?v=1665087637",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Block1_HighPoint_mobile_850x.jpg?v=1665087637",
          }}
          altText="Fall Winter 22 Image"
          className={styles.imgFullWidth}
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Fall/Winter '22
        </Heading>
      </header>

      <ul className={styles.grid4fw22}>
        {allSkus.filter(sku => [
          "high-point-125-patent-pumps",
          "high-point-125-satin-pumps",
          "next-level-125-vitello-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery1}>
          <Swiper {...swiperParams6}>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/49f682ce8bad49d0b5986d38677338aa.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          {/*<button*/}
          {/*  className={`${styles.swiperButtonPrev} swiper-navigation-prev`}*/}
          {/*  onClick={() => swiper6.slidePrev()}*/}
          {/*/>*/}
          {/*<button*/}
          {/*  className={`${styles.swiperButtonNext} swiper-navigation-next`}*/}
          {/*  onClick={() => swiper6.slideNext()}*/}
          {/*/>*/}
        </li>
      </ul>

      <ul className={styles.grid6}>
        {allSkus.filter(sku => [
          "zenith-125-vitello-boots",
          "zenith-125-patent-boots",
          "heights-125-capretto-boots",
          "heights-125-suede-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery6}>
          <Swiper {...swiperParams7}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/74828c5cc2a642ddae05a07fde416439.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/7dee25b5c0464312a85ece5c342e71fa.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper7.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper7.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <ul className={styles.grid1fw22}>
        {allSkus.filter(sku => [
          "gaze-65-nappa-sandals",
          "gaze-100-metallic-suede-sandals",
          "vivid-100-snake-print-pumps",
          "mackie-mesh-crystals-boots",
          "lynx-105-capretto-pumps",
        ].includes(sku.handle) && sku.color !== "Twilight")
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery7}>
          <Swiper {...swiperParams3}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/0926011592d249039e22c0641944565d.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/112064e104794cb78046b3a16145c248.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={3}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/f803c2e8e11542ae977e169c2dd248e2.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={4}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/3ec2c5ff1a4a4b66b2a488a639884479.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper3.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper3.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/BLOCK1_desktop_2000x.jpg?v=1659638411",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/BLOCK1_mobile_850x.jpg?v=1659638411",
        }}
        altText="Fall Winter 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "trinity-knee-high-105-patent-boots",
          "trinity-knee-high-75-patent-boots",
          "trinity-mid-calf-75-patent-boots",
          "trinity-knee-high-75-nappa-boots",
          "trinity-knee-high-105-nappa-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery1}>
          <Swiper {...swiperParams1}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/4add97d184e345deb17ac61983593910.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/fffab3b06753455ebe84f510021012d9.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper1.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper1.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/BLOCK3_desktop_2000x.jpg?v=1659638411",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/BLOCK3_mobile_850x.jpg?v=1659638411",
        }}
        altText="Fall Winter 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid8}>
        {allSkus.filter(sku => [
          "legacy-over-the-knee-75-calf-boots",
          "legacy-over-the-knee-100-calf-boots",
          "legacy-mid-calf-75-calf-boots",
          "legacy-mid-calf-100-calf-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery2}>
          <Swiper {...swiperParams2}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/ec851ff089a543abbf07288d96eba6db.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/96ce81cf101f4f709f4882aca6725f80.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper2.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper2.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Block5_reflection_limelight_2000x.jpg?v=1662745072",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Block5_mobile_reflection_limelight_850x.jpg?v=1662745072",
        }}
        altText="Fall Winter 22 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid5fw22}>
        {allSkus.filter(sku => [
          "reflection-75-disco-ball-boots",
          "limelight-40-disco-ball-pumps",
          "limelight-90-disco-ball-pumps",
        ].includes(sku.handle) && sku.color !== "Twilight")
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
      </ul>

      <ul className={styles.grid6}>
        {allSkus.filter(sku => [
          "gloss-105-patent-pumps",
          "gloss-40-patent-pumps",
          "strapped-105-capretto-pumps",
          "strapped-75-capretto-pumps",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
        <li className={styles.gridGallery6}>
          <Swiper {...swiperParams4}>
            <li
              key={1}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/0a8ed768520549a99156056e89e8fbb8.mp4"
                  type="video/mp4" />
              </video>
            </li>
            <li
              key={2}
              className={styles.swiperSlide}
            >
              {// eslint-disable-next-line jsx-a11y/media-has-caption
              }
              <video autoPlay loop muted playsInline>
                <source
                  src="https://cdn.shopify.com/videos/c/o/v/d7ec32284c2d4ea58962cafa2128c497.mp4"
                  type="video/mp4" />
              </video>
            </li>
          </Swiper>
          <button
            className={`${styles.swiperButtonPrev} swiper-navigation-prev`}
            onClick={() => swiper4.slidePrev()}
            aria-label="Previous"
          />
          <button
            className={`${styles.swiperButtonNext} swiper-navigation-next`}
            onClick={() => swiper4.slideNext()}
            aria-label="Next"
          />
        </li>
      </ul>

      <ul className={styles.grid3fw22}>
        {allSkus.filter(sku => [
          "cantor-boots",
          "buckle-up-nappa-boots",
          "monday-morning-20-nappa-boots",
          "monday-morning-suede-boots",
          "weekend-20-suede-boots",
          // "halo-pvc-pumps",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw22`}
            />
          ))}
      </ul>
    </div>
  )
}

export default FW22

export const query = graphql`
    query FW22Query {
        allProductStyle(filter: {handle: {in: [
            "cantor-boots",
            "buckle-up-nappa-boots",
            "gloss-105-patent-pumps",
            "gloss-40-patent-pumps",
#            "halo-pvc-pumps",
            "legacy-mid-calf-100-calf-boots",
            "legacy-mid-calf-75-calf-boots",
            "legacy-over-the-knee-100-calf-boots",
            "legacy-over-the-knee-75-calf-boots",
            "monday-morning-20-nappa-boots",
            "monday-morning-suede-boots",
            "strapped-105-capretto-pumps",
            "strapped-75-capretto-pumps",
            "trinity-knee-high-105-nappa-boots",
            "trinity-knee-high-105-patent-boots",
            "trinity-knee-high-75-nappa-boots",
            "trinity-knee-high-75-patent-boots",
            "trinity-mid-calf-75-patent-boots",
            "weekend-20-suede-boots",
            "heights-125-capretto-boots",
            "heights-125-suede-boots",
            "mackie-mesh-crystals-boots",
            "reflection-75-disco-ball-boots",
            "limelight-40-disco-ball-pumps",
            "limelight-90-disco-ball-pumps",
            "gaze-65-nappa-sandals",
            "gaze-100-metallic-suede-sandals",
            "high-point-125-patent-pumps",
            "high-point-125-satin-pumps",
            "lynx-105-capretto-pumps",
            "next-level-125-vitello-sandals",
            "vivid-100-snake-print-pumps",
            "zenith-125-patent-boots",
            "zenith-125-vitello-boots",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
